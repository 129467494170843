import React, { lazy, Suspense } from 'react';
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';

import Loader from './components/loader';
const LazyApp = lazy(() => import('./App'))

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <Suspense fallback={<Loader />}>
      <LazyApp />
    </Suspense>
  </React.StrictMode>
);

reportWebVitals();
